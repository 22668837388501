import api from "@/api";
import CommonService from '@/services/CommonService'
import moment from "moment";

const CommonDataService = {
	methods: {
		getSources: function () {

			let _this = this;
			return api.get('/common/sources', {
				params: {}
			})
				.then((response) => {

					if (process.env.NODE_ENV == "development")
						console.debug('getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								if(source.source_see == 1) {
									list.push({
										value: source.id.toString(),
										name: source.name
									});
								}
							});
						}

						if (process.env.NODE_ENV == "development")
							console.info('CommonDataService::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}
				}).catch(function (error) {

					if (process.env.NODE_ENV == "development")
						console.error('getSources()::axios', error);

				});
		},
	}
};

export {CommonDataService};